<template>
  <div>
    <b-tabs content-class="mt-3" justified class="custom-tabs d-sm-block">
      <b-tab
        :title="`${getTitle(i)}`"
        v-for="i in totalTabs"
        :key="i"
        :disabled="getRecurrentListBySlice(i).length === 0"
      >
        <div class="tab-content">
          <div
            class="tab-content-item"
            v-for="(rc, index) in getRecurrentListBySlice(i)"
            :key="index"
          >
            <TimeSlot :item="rc" :payment_status="multiPackage.payment_status" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div
      class="tab-content-item d-none"
      v-for="(rc, index) in multiPackage.recurrentList"
      :key="index"
    >
      <çt :item="rc" :payment_status="multiPackage.payment_status" />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
// import ApiClient from '../services/'
// import moment from 'moment'
import TimeSlot from './TimeSlot'

const BookingStore = createNamespacedHelpers('booking')
const CalendarStore = createNamespacedHelpers('calendar')

@Component({
  components: { TimeSlot },
  computed: {
    ...BookingStore.mapState(['multiPackage']),
    ...CalendarStore.mapState(['isFetchingCalendar']),
  },
  methods: {
    ...CalendarStore.mapActions(['updateMaidCalendarByMonth']),
  },
  mounted() {
    console.log('xxxxxxxxxxxxxxxx', this.multiPackage.payment_status)
  },
})
export default class PackageTab extends Vue {
  currentItemIndex = null
  changeForm = {}
  calendar = [] // Calendar Attribute
  maidTotalHours = []
  maidCalendarAllMonth = []
  maidCalendarAvailable = {}
  isFetchingData = false

  getRecurrentListBySlice(index) {
    const start = (index - 1) * 8
    const end = 7 + (index - 1) * 8

    return this.multiPackage.recurrentList.slice(start, end + 1)
  }

  getTitle(index) {
    // index ==> 1,2,3,4
    const titles = ['1 - 8', '9 - 16', '17 - 24', '25 - 28']
    if (index === this.totalTabs)
      return `${titles[index - 1].split('-')[0]} - ${this.multiPackage.recurrentList.length}`
    return titles[index - 1]
  }

  @Watch('multiPackage.recurrentDates')
  onRecurrentDatesChanged(newVal) {
    this.currentItemIndex = 0
  }

  get totalTabs() {
    return Math.ceil(this.multiPackage.recurrentList.length / 8)
  }

  get recurrentList() {
    return this.multiPackage.recurrentList
  }
}
</script>
